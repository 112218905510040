import { BASE_URL, get, useFetch } from 'utils/sdk';

export interface CropChoicesListResponse {
  crop_id: number;
  name: string;
}

export interface CropVarietyChoicesListResponse {
  id: number;
  name: string;
  duration_in_weeks: number;
}

export const useCropChoicesList = () =>
  useFetch<Array<CropChoicesListResponse>>(`/api/crops/choices/`);

export const useCropVarietyChoicesList = ({
  cropId
}: {
  cropId?: string | null;
}) =>
  useFetch<Array<CropVarietyChoicesListResponse>>(
    `/api/crops/${cropId}/variety/choices/`
  );

export const getCropVarietyChoicesList = (cropId: number) =>
  get(`${BASE_URL}/api/crops/${cropId}/variety/choices/`) as Promise<
    Array<CropVarietyChoicesListResponse>
  >;
