import { isNil, uniq, uniqBy } from 'lodash';

import { CONTRACT_STATUSES } from 'entities/Contracts/constants';
import DashboardFilter from 'entities/Dashboard/components/DashboardFilter';
import { DashboardFiltersProps as ContractsFilterProps } from 'entities/Dashboard/sdk';
import { ContractsDashboardListResponse } from 'entities/Dashboard/sdk';

import { SearchInput } from 'components/Filters';

interface DashboardFiltersProps {
  onFilter: (filters: ContractsFilterProps) => void;
  filters: ContractsFilterProps;
  contracts?: Array<ContractsDashboardListResponse>;
}

const DashboardFilters = ({
  onFilter,
  contracts,
  filters
}: DashboardFiltersProps) => {
  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    onFilter({ search: value, crop: filters.crop, status: filters.status });
  };

  const contractsOutgrowers =
    contracts &&
    contracts.map((contract) => ({
      outgrowerId: contract.outgrower_id,
      outgrowerFirsName: contract.outgrower_first_name,
      outgrowerLastName: contract.outgrower_last_name
    }));

  const uniqueOutgrowers = uniqBy(contractsOutgrowers, 'outgrowerId');

  const outgrowerFilterOptions = uniqueOutgrowers.map((outgrower) => ({
    label: `${outgrower.outgrowerFirsName} ${outgrower.outgrowerLastName}`,
    value: outgrower.outgrowerId
  }));

  const contractsLeadFarmers =
    contracts &&
    contracts
      .filter((contract) => !isNil(contract.lead_outgrower_id))
      .map((contract) => ({
        leadOutgrowerId: contract.lead_outgrower_id,
        leadOutgrowerFirstName: contract.lead_outgrower_first_name,
        leadOutgrowerLastName: contract.lead_outgrower_last_name
      }));

  const uniqueLeadFarmers = uniqBy(contractsLeadFarmers, 'leadOutgrowerId');

  const leadFarmerFilterOptions = uniqueLeadFarmers.map((leadFarmer) => ({
    label: `${leadFarmer.leadOutgrowerFirstName} ${leadFarmer.leadOutgrowerLastName}`,
    value: leadFarmer.leadOutgrowerId
  }));

  const contractsCountries =
    contracts && contracts.map((contract) => contract.farm_country);

  const uniqueLocations = uniq(contractsCountries);

  const locationFilterOptions = uniqueLocations.map((location) => ({
    label: location,
    value: location
  }));

  const statusFilterOptions = [
    {
      label: 'Active',
      value: CONTRACT_STATUSES.ACTIVE
    },
    {
      label: 'Upcoming',
      value: CONTRACT_STATUSES.UPCOMING
    }
  ];

  return (
    <div className="flex justify-between">
      <div className="flex gap-2">
        <DashboardFilter
          filterName="status"
          filters={filters}
          label="Status"
          onFilter={onFilter}
          filterOptions={statusFilterOptions}
        />
        <DashboardFilter
          filterName="outgrower"
          filters={filters}
          label="Farmer"
          onFilter={onFilter}
          filterOptions={outgrowerFilterOptions}
        />
        <DashboardFilter
          filterName="lead_outgrower"
          filters={filters}
          label="Lead Farmer"
          onFilter={onFilter}
          filterOptions={leadFarmerFilterOptions}
        />
        <DashboardFilter
          filterName="country"
          filters={filters}
          label="Location"
          onFilter={onFilter}
          filterOptions={locationFilterOptions}
        />
      </div>
      <SearchInput placeholder="Search by keyword" onSearch={onSearch} />
    </div>
  );
};

export default DashboardFilters;
