import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { BackArrowIcon } from 'assets';
import { toNumber } from 'lodash';

import ContractCreateForm from 'entities/Contracts/components/ContractCreateForm';
import ContractCreatedDialog from 'entities/Contracts/components/ContractCreatedDialog';
import { ContractCreateProps, contractCreate } from 'entities/Contracts/sdk';
import { useCropChoicesList } from 'entities/Crops/sdk';
import { useOutgrowerFarms } from 'entities/Outgrower/sdk';

import { useLayout } from 'components/Layout/hooks';
import PageTitle from 'components/PageTitle';

const ContractsCreate = () => {
  const { setPageTitle } = useLayout();
  const { outgrowerId } = useParams();
  const { data: crops, isLoading: cropsLoading } = useCropChoicesList();
  const { data: outgrowerFarms } = useOutgrowerFarms({
    outgrowerId
  });
  const [contractCreateDialogOpened, setContractCreatedDialogOpened] =
    useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setPageTitle(
      <PageTitle
        icon={<BackArrowIcon onClick={() => navigate(-1)} />}
        text="Assign Contract"
      ></PageTitle>
    );

    return () => setPageTitle(null);
  }, [setPageTitle, navigate]);

  const handleSave = (data: ContractCreateProps) => {
    return contractCreate(data).then(() => {
      setContractCreatedDialogOpened(true);
    });
  };

  return (
    <div className="flex h-full flex-col">
      {crops && !cropsLoading && outgrowerFarms && (
        <ContractCreateForm
          onSave={handleSave}
          crops={crops}
          outgrowerId={toNumber(outgrowerId)}
          outgrowerFarms={outgrowerFarms}
        />
      )}
      {contractCreateDialogOpened && outgrowerId && (
        <ContractCreatedDialog
          onClose={() => setContractCreatedDialogOpened(false)}
          outgrowerId={outgrowerId}
        />
      )}
    </div>
  );
};

export default ContractsCreate;
