import { MenuItem, TextField } from '@mui/material';

import { CountryOption } from 'entities/Outgrower/sdk';

import { Control } from 'utils/forms';

import FormAutoCompleteField from '../AutoCompleteField';

interface FieldProps {
  name: string;
  label: string;
  placeholder: string;
  error: boolean;
  helperText: string | undefined;
}

interface FormCountryFieldProps {
  countries: Array<CountryOption>;
  control: Control<any>;
  fieldProps: FieldProps;
}

const FormCountryField = ({
  countries,
  control,
  fieldProps: { name, label, placeholder, error, helperText }
}: FormCountryFieldProps) => (
  <FormAutoCompleteField
    name={name}
    control={control}
    rules={{ required: 'This field is required' }}
    fieldProps={{
      options: countries,
      getOptionLabel: (option: CountryOption) => option?.label ?? '',
      renderOption: (
        props: React.HTMLAttributes<HTMLLIElement>,
        option: CountryOption
      ) => (
        <MenuItem key={option.value} value={option.value} {...props}>
          <img
            loading="lazy"
            className="mr-2"
            width="20"
            srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`}
            src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`}
            alt={`${option.label} flag`}
          />
          {option.label}
        </MenuItem>
      ),
      renderInput: (params: any) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          slotProps={{
            htmlInput: {
              ...params.inputProps,
              autoComplete: 'new-password' // disable autocomplete and autofill
            }
          }}
          error={error}
          helperText={helperText}
        />
      )
    }}
  />
);

export default FormCountryField;
