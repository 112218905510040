import { ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

import { default as Card, CardContent, CardProps } from 'components/Card';
import Text from 'components/Text';

interface StatisticsCardProps extends CardProps {
  icon: ReactNode;
  title: string;
  value: ReactNode;
  className?: string;
}

const StatisticsCard = ({
  icon,
  title,
  value,
  className
}: StatisticsCardProps) => {
  return (
    <Card
      classes={{
        root: twMerge(
          'flex flex-col px-3 py-[10px] min-w-[184px] h-[92px] border border-solid border-[#D4DAE1] rounded-xl bg-[#b3cbbd33]',
          className
        )
      }}
    >
      <CardContent
        classes={{
          root: 'flex flex-col w-full h-full text-[#757575] gap-4'
        }}
      >
        <div className="flex flex-row gap-1">
          {icon}
          <Text classes={{ root: 'text-sm' }}>{title}</Text>
        </div>
        <div className="flex flex-row items-end gap-1">{value}</div>
      </CardContent>
    </Card>
  );
};

export default StatisticsCard;
