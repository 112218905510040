import { generatePath, useNavigate } from 'react-router-dom';

import { capitalize } from 'lodash';

import { SOLID_TYPE_OPTIONS } from 'entities/Outgrower/constants';
import { OutgrowerFarmResponse } from 'entities/Outgrower/sdk';

import Avatar from 'components/Avatar';
import Text from 'components/Text';

import { URLS } from 'config/urls';

interface OutgrowerFarmSummaryProps {
  farm: OutgrowerFarmResponse;
}

const SummaryHeader = ({ children }: { children: React.ReactNode }) => (
  <Text
    color="wet-green"
    className="font-normal leading-[22px] tracking-[0.15px]"
  >
    {children}
  </Text>
);

const SummaryValue = ({ children }: { children: React.ReactNode }) => (
  <Text color="wet-green" className="font-semibold leading-6 tracking-[0.15px]">
    {children}
  </Text>
);

const OutgrowerFarmSummary = ({ farm }: OutgrowerFarmSummaryProps) => {
  const navigate = useNavigate();

  const soilType = SOLID_TYPE_OPTIONS.find(
    (option) => option.value === farm.soil_type
  )?.label;

  return (
    <div
      className={
        'max-h-fit w-[491px] gap-[10px] rounded-[4px] border border-solid border-[#D4DAE1] p-3'
      }
    >
      <div className="flex items-center gap-2 border-0 border-b border-solid border-b-[#D4DAE1] pb-1">
        <Avatar className="h-[36px] w-[36px]" />
        <Text
          color="wet-green"
          className="cursor-pointer text-2xl font-semibold leading-9 tracking-[0.15px]"
          onClick={() =>
            navigate(
              generatePath(URLS.OUTGROWER_PROFILE, {
                outgrowerId: farm.outgrower.id.toString()
              })
            )
          }
        >
          {farm.outgrower.first_name} {farm.outgrower.last_name}
        </Text>
      </div>
      <div className="flex flex-col gap-2 pt-2">
        <div className="flex justify-between">
          <SummaryHeader>Farm name</SummaryHeader>
          <SummaryValue>{farm.name}</SummaryValue>
        </div>
        <div className="flex justify-between">
          <SummaryHeader>Farm size</SummaryHeader>
          <SummaryValue>{farm.size} ha</SummaryValue>
        </div>
        <div className="flex justify-between">
          <SummaryHeader>Land type</SummaryHeader>
          <SummaryValue>{capitalize(farm.ownership)}</SummaryValue>
        </div>
        <div className="flex justify-between">
          <SummaryHeader>Soil type</SummaryHeader>
          <SummaryValue>{soilType}</SummaryValue>
        </div>
        <div className="flex justify-between">
          <SummaryHeader>Irrigation</SummaryHeader>
          <SummaryValue>{farm.irrigation ? 'Yes' : 'No'}</SummaryValue>
        </div>
        <div className="flex justify-between">
          <SummaryHeader>Country</SummaryHeader>
          <SummaryValue>{farm.country}</SummaryValue>
        </div>
        <div className="flex justify-between">
          <SummaryHeader>Region/ State / Province</SummaryHeader>
          <SummaryValue>{farm.region}</SummaryValue>
        </div>
        <div className="flex justify-between">
          <SummaryHeader>Town</SummaryHeader>
          <SummaryValue>{farm.town}</SummaryValue>
        </div>
        <div className="flex justify-between">
          <SummaryHeader>Street</SummaryHeader>
          <SummaryValue>
            {farm.street} {farm.street_number}
          </SummaryValue>
        </div>
      </div>
    </div>
  );
};

export default OutgrowerFarmSummary;
