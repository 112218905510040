import { twMerge } from 'tailwind-merge';

import { TableCell } from 'components/Table';

interface TableBodyCellProps {
  content: string | number | JSX.Element;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const TableBodyCell = ({ content, className, onClick }: TableBodyCellProps) => (
  <TableCell
    onClick={onClick}
    align="center"
    classes={{
      root: twMerge(
        'p-5 text-xs font-normal leading-[14px] h-[56px] text-wet-green border-x-[#c5c5c54d]',
        className
      )
    }}
  >
    {content}
  </TableCell>
);

export default TableBodyCell;
