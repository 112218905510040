import { SortDownArrowIcon, SortUpArrowIcon } from 'assets';
import { twMerge } from 'tailwind-merge';

import { TableCell, TableSortLabel } from 'components/Table';

import { ListSortProps } from 'utils/sdk';

interface SortableTableHeaderCellProps {
  content: string | number | JSX.Element;
  className?: string;
  onSort: (sort: ListSortProps) => void;
  sortBy: ListSortProps;
  sortName: string;
}

const SortUpArrow = () => (
  <div className="flex flex-col gap-1 pl-2">
    <SortUpArrowIcon />
    <SortDownArrowIcon className="opacity-30" />
  </div>
);

const SortDownArrow = () => (
  <div className="flex flex-col gap-1 pl-2">
    <SortUpArrowIcon className="opacity-30" />
    <SortDownArrowIcon />
  </div>
);

const DefaultArrows = () => (
  <div className="flex flex-col gap-1 pl-2">
    <SortUpArrowIcon className="opacity-30" />
    <SortDownArrowIcon className="opacity-30" />
  </div>
);

const SortableTableHeaderCell = ({
  content,
  className,
  onSort,
  sortName,
  sortBy
}: SortableTableHeaderCellProps) => {
  const handleSort = () => {
    let newDirection = 'asc';

    if (sortBy.sort === sortName) {
      newDirection = sortBy.direction === 'asc' ? 'desc' : 'asc';
    }

    onSort({
      sort: sortName,
      direction: newDirection
    });
  };

  const SortIcon = () => {
    if (sortBy.sort === sortName) {
      if (sortBy.direction === 'asc') {
        return <SortUpArrow />;
      }

      if (sortBy.direction === 'desc') {
        return <SortDownArrow />;
      }
    }

    return <DefaultArrows />;
  };

  return (
    <TableCell
      component="th"
      key={content as string}
      align="center"
      classes={{
        root: twMerge(
          'px-[17px] py-3 text-base font-semibold	leading-6	tracking-[0.15px] top-[-1px] h-[82px]',
          className
        )
      }}
      sx={{
        borderRight: '1px solid #c5c5c54d',
        borderLeft: '1px solid #c5c5c54d',
        borderBottom: '1px solid #c5c5c54d'
      }}
    >
      <TableSortLabel
        active={true}
        IconComponent={SortIcon}
        onClick={handleSort}
      >
        {content}
      </TableSortLabel>
    </TableCell>
  );
};

export default SortableTableHeaderCell;
