import { useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { XIcon } from 'assets';

import ContractCloseDialog from 'entities/Contracts/components/ContractCloseDialog';
import { OutgrowerContractsListResponse } from 'entities/Outgrower/sdk';

import {
  SortableTableHeaderCell,
  Table,
  TableBody,
  TableBodyCell,
  TableContainer,
  TableEmptyState,
  TableHead,
  TableHeaderCell,
  TableRow
} from 'components/Table';

import { SwrMutator } from 'utils/sdk';
import { ListSortProps } from 'utils/sdk';

import { URLS } from 'config/urls';

interface OpenOutgrowerContractsListTableProps {
  contracts: OutgrowerContractsListResponse['contracts'];
  outgrower: OutgrowerContractsListResponse['outgrower'];
  onSort: (sort: ListSortProps) => void;
  sort: ListSortProps;
  contractsRefetch: SwrMutator;
}

const OpenOutgrowerContractsListTable = ({
  contractsRefetch,
  contracts,
  outgrower,
  onSort,
  sort
}: OpenOutgrowerContractsListTableProps) => {
  const navigate = useNavigate();
  const tableIsEmpty = contracts.length === 0;

  const [contractCloseDialogOpened, setContractCloseDialogOpened] =
    useState(false);
  const [contractId, setContractId] = useState<number | ''>('');

  const handleDialogOpen = (contractId: number) => {
    setContractId(contractId);
    setContractCloseDialogOpened(true);
  };

  const handleDialogClose = () => {
    setContractCloseDialogOpened(false);
    contractsRefetch();
  };

  return (
    <TableContainer
      className="h-full"
      classes={{ root: 'border-[#c5c5c54d] border-b-0' }}
    >
      <Table
        stickyHeader
        classes={{
          root: `border-solid border-b border-[#c5c5c54d] border-x-0 border-t-0 ${tableIsEmpty && 'h-full'} `
        }}
      >
        <TableHead>
          <TableRow>
            <SortableTableHeaderCell
              content="Contract ID"
              onSort={onSort}
              sortBy={sort}
              sortName={'id'}
              className="px-3"
            />
            <TableHeaderCell content="Crop" className="px-3" />
            <SortableTableHeaderCell
              content="Start Date"
              onSort={onSort}
              sortBy={sort}
              sortName={'execution_start_date'}
              className="px-3"
            />
            <SortableTableHeaderCell
              content="Expected Harvest Date"
              onSort={onSort}
              sortBy={sort}
              sortName={'expected_harverst_date'}
              className="px-3"
            />
            <SortableTableHeaderCell
              content="Expected Harvest"
              onSort={onSort}
              sortBy={sort}
              sortName={'expected_harvest_amount'}
              className="px-3"
            />
            <SortableTableHeaderCell
              content="Expected  Farmgate Price"
              onSort={onSort}
              sortBy={sort}
              sortName={'expected_farmgate_price'}
              className="px-3"
            />
            <TableHeaderCell content="Weekly Task" className="px-3" />
            <TableHeaderCell content="Close" className="px-3" />
          </TableRow>
        </TableHead>
        <TableBody>
          {tableIsEmpty && (
            <TableEmptyState tableColumns={9} text={'No contracts available'} />
          )}
          {contracts.map((contract) => (
            <TableRow
              hover
              onClick={() =>
                navigate(
                  generatePath(URLS.OUTGROWER_CONTRACT_DIARY, {
                    contractId: contract.id.toString(),
                    outgrowerId: outgrower.id.toString()
                  })
                )
              }
              key={contract.id}
              classes={{
                root: 'odd:bg-white even:bg-[#b3cbbd]/10 cursor-pointer'
              }}
            >
              <TableBodyCell content={contract.id} />
              <TableBodyCell content={contract.crop} className="font-medium" />
              <TableBodyCell content={contract.execution_start_date} />
              <TableBodyCell content={contract.expected_harverst_date} />
              <TableBodyCell
                content={`${contract.expected_harvest_amount} t`}
              />
              <TableBodyCell
                content={`${contract.expected_farmgate_price} t`}
              />
              <TableBodyCell content={'N/A'} />
              <TableBodyCell
                onClick={(event) => {
                  event.stopPropagation();
                  handleDialogOpen(contract.id);
                }}
                content={<XIcon className="mx-1.5" />}
              />
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {contractCloseDialogOpened && contractId && (
        <ContractCloseDialog
          contractId={contractId}
          onClose={handleDialogClose}
        />
      )}
    </TableContainer>
  );
};

export default OpenOutgrowerContractsListTable;
