import { generatePath, useNavigate } from 'react-router-dom';

import { ContractListResponse } from 'entities/Contracts/sdk';

import {
  SortableTableHeaderCell,
  Table,
  TableBody,
  TableBodyCell,
  TableContainer,
  TableEmptyState,
  TableHead,
  TableHeaderCell,
  TableRow
} from 'components/Table';
import Tooltip from 'components/Tooltip';

import { ListSortProps } from 'utils/sdk';

import { URLS } from 'config/urls';

interface ClosedContractsListTableProps {
  contracts: Array<ContractListResponse>;
  onSort: (sort: ListSortProps) => void;
  sort: ListSortProps;
}

const ClosedContractsListTable = ({
  contracts,
  onSort,
  sort
}: ClosedContractsListTableProps) => {
  const navigate = useNavigate();

  const tableIsEmpty = contracts.length === 0;

  return (
    <TableContainer
      className="h-full"
      classes={{ root: 'border-[#c5c5c54d] border-b-0' }}
    >
      <Table
        stickyHeader
        classes={{
          root: `border-solid border-b border-[#c5c5c54d] border-x-0 border-t-0 ${tableIsEmpty && 'h-full'} `
        }}
      >
        <TableHead>
          <TableRow>
            <SortableTableHeaderCell
              content="Contract ID"
              onSort={onSort}
              sortBy={sort}
              sortName={'id'}
              className="w-[107px]"
            />
            <TableHeaderCell content="Farmer" className="min-w-[150px]" />
            <TableHeaderCell content="Crop" className="min-w-[150px]" />
            <SortableTableHeaderCell
              content="Start Date"
              onSort={onSort}
              sortBy={sort}
              sortName={'execution_start_date'}
            />
            <SortableTableHeaderCell
              content="Expected Harvest Date"
              onSort={onSort}
              sortBy={sort}
              sortName={'expected_harverst_date'}
            />
            <SortableTableHeaderCell
              content="Actual Harvest Date"
              onSort={onSort}
              sortBy={sort}
              sortName={'actual_harvest_date'}
            />
            <SortableTableHeaderCell
              content="Expected Harvest"
              onSort={onSort}
              sortBy={sort}
              sortName={'expected_harvest_amount'}
            />
            <SortableTableHeaderCell
              content="Actual Harvest"
              onSort={onSort}
              sortBy={sort}
              sortName={'actual_harvest_amount'}
            />
            <SortableTableHeaderCell
              content="Exposure"
              onSort={onSort}
              sortBy={sort}
              sortName={'exposure'}
              className="px-3"
            />
            <TableHeaderCell content="Closing reason" />
          </TableRow>
        </TableHead>
        <TableBody>
          {tableIsEmpty && (
            <TableEmptyState tableColumns={9} text={'No contracts available'} />
          )}
          {contracts.map((contract) => (
            <TableRow
              hover
              key={contract.id}
              classes={{
                root: 'odd:bg-white even:bg-[#b3cbbd]/10 cursor-pointer'
              }}
              onClick={() =>
                navigate(
                  generatePath(URLS.OUTGROWER_CONTRACT_DIARY, {
                    contractId: contract.id.toString(),
                    outgrowerId: contract.outgrower_id.toString()
                  })
                )
              }
            >
              <TableBodyCell content={contract.id} className="w-[107px]" />
              <TableBodyCell
                content={`${contract.outgrower_first_name} ${contract.outgrower_last_name}`}
                className="min-w-[150px] font-medium"
              />
              <TableBodyCell
                content={contract.crop}
                className="min-w-[150px] font-medium"
              />
              <TableBodyCell content={contract.execution_start_date} />
              <TableBodyCell content={contract.expected_harverst_date} />
              <TableBodyCell content={contract.actual_harvest_date || 'N/A'} />
              <TableBodyCell
                content={`${contract.expected_harvest_amount} t`}
              />
              <TableBodyCell
                content={`${contract.actual_harvest_amount || 0} t`}
              />
              <TableBodyCell content={`$${contract.exposure || 0}`} />
              <TableBodyCell
                content={
                  contract.termination_reason ? (
                    <Tooltip
                      className="text-xs text-wet-green"
                      title={contract.termination_reason}
                    >
                      <div>Terminated</div>
                    </Tooltip>
                  ) : (
                    'Season end'
                  )
                }
              />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ClosedContractsListTable;
