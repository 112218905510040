import { useNavigate } from 'react-router-dom';

import { FarmerLogo, LabeledLogo } from 'assets';
import { setSentryUser } from 'sentry';

import LoginForm from 'entities/Auth/components/LoginForm';
import { LoginResponse, login } from 'entities/Auth/sdk';

import Text from 'components/Text';

import { saveToken } from 'utils/storage';

import { URLS } from 'config/urls';

const Login = () => {
  const navigate = useNavigate();
  const handleSave = (data: { email: string; password: string }) => {
    return login(data).then((response: LoginResponse) => {
      saveToken(response.token);
      setSentryUser({ email: response.user.email });
      navigate(URLS.DASHBOARD);
    });
  };
  return (
    <>
      <div className="flex h-screen w-screen flex-row">
        {/* Left Side */}
        <div className="flex h-full w-1/2 items-center">
          <div className="flex h-fit w-full flex-col items-center">
            <div className="flex flex-col items-center">
              <LabeledLogo />
              <div className="m-0 mt-[-40px] flex flex-col gap-[54px]">
                <Text
                  classes={{
                    root: 'font-medium text-[32px] leading-[48px] text-center'
                  }}
                >
                  Welcome to sproot
                </Text>
                <LoginForm onSave={handleSave} />
              </div>
            </div>
          </div>
        </div>

        {/* Right Side */}
        <div className="bg flex h-full w-1/2 bg-fresh-green">
          <div className="flex h-full w-full flex-col justify-center">
            <div className="flex h-fit w-full flex-col items-center gap-[31px]">
              <FarmerLogo />
              <div className="flex flex-col gap-2">
                <Text
                  classes={{
                    root: 'font-medium text-[32px] leading-[48px] text-center'
                  }}
                >
                  Manage all outgrowers in one place
                </Text>
                <Text
                  classes={{
                    root: 'text-xl font-normal text-center'
                  }}
                >
                  Everything you need in one platform.
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
