import { generatePath, useNavigate } from 'react-router-dom';

import { ContractsDashboardListResponse } from 'entities/Dashboard/sdk';

import {
  SortableTableHeaderCell,
  Table,
  TableBody,
  TableBodyCell,
  TableContainer,
  TableEmptyState,
  TableHead,
  TableHeaderCell,
  TableRow
} from 'components/Table';

import { ListSortProps } from 'utils/sdk';

import { URLS } from 'config/urls';

interface DashboardListTableProps {
  contracts: Array<ContractsDashboardListResponse>;
  onSort: (sort: ListSortProps) => void;
  sort: ListSortProps;
}

const DashboardListTable = ({
  contracts,
  onSort,
  sort
}: DashboardListTableProps) => {
  const navigate = useNavigate();

  const tableIsEmpty = contracts.length === 0;

  return (
    <TableContainer
      className="h-full"
      classes={{ root: 'border-[#c5c5c54d] border-b-0' }}
    >
      <Table
        stickyHeader
        classes={{
          root: `border-solid border-b border-[#c5c5c54d] border-x-0 border-t-0 ${tableIsEmpty && 'h-full'} `
        }}
      >
        <TableHead>
          <TableRow>
            <SortableTableHeaderCell
              content="Contract ID"
              onSort={onSort}
              sortBy={sort}
              sortName={'id'}
              className="w-[107px]"
            />
            <TableHeaderCell content="Farmer" />
            <TableHeaderCell content="Farm" />
            <TableHeaderCell content="Lead Farmer" />
            <TableHeaderCell content="Location" />
            <SortableTableHeaderCell
              content="Size"
              onSort={onSort}
              sortBy={sort}
              sortName={'land_size'}
              className="w-[107px]"
            />
            <TableHeaderCell content="Crop" />
            <TableHeaderCell content="Growth Stage" className="w-[97px]" />
            <SortableTableHeaderCell
              content="Exposure"
              onSort={onSort}
              sortBy={sort}
              sortName={'exposure'}
              className="w-[107px]"
            />
            <SortableTableHeaderCell
              content="Expected Harvest"
              onSort={onSort}
              sortBy={sort}
              sortName={'expected_harvest_amount'}
              className="w-[107px]"
            />
            <TableHeaderCell content="Weekly Task" className="w-[130px]" />
          </TableRow>
        </TableHead>
        <TableBody>
          {tableIsEmpty && (
            <TableEmptyState
              tableColumns={11}
              text={'No contracts available'}
            />
          )}
          {contracts.map((contract) => (
            <TableRow
              hover
              key={contract.id}
              classes={{
                root: 'odd:bg-white even:bg-[#b3cbbd]/10 cursor-pointer'
              }}
              onClick={() =>
                navigate(
                  generatePath(URLS.OUTGROWER_CONTRACT_DIARY, {
                    outgrowerId: contract.outgrower_id.toString(),
                    contractId: contract.id.toString()
                  })
                )
              }
            >
              <TableBodyCell content={contract.id} className="w-[107px]" />
              <TableBodyCell
                content={`${contract.outgrower_first_name} ${contract.outgrower_last_name}`}
                className="font-medium"
              />
              <TableBodyCell
                content={contract.farm_name}
                className="font-medium"
              />
              <TableBodyCell
                content={`${contract.lead_outgrower_first_name || '-'} ${contract.lead_outgrower_last_name || '-'}`}
                className="font-medium"
              />
              <TableBodyCell
                content={`${contract.farm_country}, ${contract.farm_town}`}
              />
              <TableBodyCell content={contract.land_size} />
              <TableBodyCell content={contract.crop} />
              <TableBodyCell content={'N/A'} className="w-[97px]" />
              <TableBodyCell content={`$${contract.exposure || 0}`} />
              <TableBodyCell
                content={`${contract.expected_harvest_amount} t`}
              />
              <TableBodyCell content={'N/A'} className="w-[130px]" />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DashboardListTable;
