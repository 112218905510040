import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements
} from 'react-router-dom';

import ContractCreatePage from 'pages/ContractCreate';
import ContractsList from 'pages/ContractsList';
import DashboardPage from 'pages/Dashboard';
import LoginPage from 'pages/Login';
import OutgrowerContractDiaryPage from 'pages/OutgrowerContractDiary';
import OutgrowerContractGalleryPage from 'pages/OutgrowerContractGallery';
import OutgrowerContractsPage from 'pages/OutgrowerContracts';
import OutgrowerCreatePage from 'pages/OutgrowerCreate';
import OutgrowerProfilePage from 'pages/OutgrowerProfile';
import OutgrowersListPage from 'pages/OutgrowersList';

import AnonimousGuard from 'components/AnonimousGuard';
import AuthGuard from 'components/AuthGuard';
import DetailsLayout from 'components/Layout/DetailsLayout';
import MainLayout from 'components/Layout/MainLayout';

import { URLS } from 'config/urls';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<AnonimousGuard />}>
        <Route path={URLS.LOGIN} element={<LoginPage />} />
      </Route>

      <Route element={<AuthGuard />}>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<DashboardPage />} />
          <Route path={URLS.DASHBOARD} element={<DashboardPage />} />
          <Route path={URLS.OUTGROWERS} element={<OutgrowersListPage />} />
          <Route
            path={URLS.OUTGROWER_PROFILE}
            element={<OutgrowerProfilePage />}
          />
          <Route
            path={URLS.OUTGROWER_CREATE}
            element={<OutgrowerCreatePage />}
          />
          <Route
            path={URLS.OUTGROWER_CONTRACTS}
            element={<OutgrowerContractsPage />}
          />
          <Route
            path={URLS.OUTGROWER_CONTRACT_CREATE}
            element={<ContractCreatePage />}
          />
          <Route path={URLS.CONTRACTS} element={<ContractsList />} />
        </Route>
        <Route path="/" element={<DetailsLayout />}>
          <Route
            path={URLS.OUTGROWER_CONTRACT_DIARY}
            element={<OutgrowerContractDiaryPage />}
          />
          <Route
            path={URLS.OUTGROWER_CONTRACT_GALLERY}
            element={<OutgrowerContractGalleryPage />}
          />
        </Route>
      </Route>
    </>
  )
);

const Routes = () => <RouterProvider router={router} />;
export default Routes;
