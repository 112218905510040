import React from 'react';

import AutoComplete, { AutocompleteProps } from 'components/AutoComplete';

import {
  Control,
  Controller,
  FieldValues,
  Path,
  RegisterOptions
} from 'utils/forms';

interface FormAutoCompleteFieldProps<T extends FieldValues> {
  name: Path<T>;
  control: Control<T, any>;
  rules?: RegisterOptions<T>;
  fieldProps: AutocompleteProps<
    any,
    boolean,
    boolean,
    boolean,
    React.ElementType
  >;
}

interface AutoCompleteOption {
  value: string;
  label: string;
}

const FormAutoCompleteField = <T extends FieldValues>({
  name,
  control,
  rules,
  fieldProps
}: FormAutoCompleteFieldProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <AutoComplete
          {...fieldProps}
          {...field}
          value={field?.value ?? ''}
          onChange={(_, newValue: AutoCompleteOption) =>
            field.onChange(newValue ?? '')
          }
        />
      )}
    />
  );
};

export default FormAutoCompleteField;
