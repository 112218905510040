import { SortRequestProps, useFetch } from 'utils/sdk';

export interface DashboardStatisticsResponse {
  contracts_count: number;
  expected_harvest: number;
  financial_exposure: number;
  expected_farmgate_price: number;
}

export const useDashboardStatistics = ({
  filters,
  sort
}: {
  filters?: DashboardFiltersProps;
  sort: SortRequestProps;
}) =>
  useFetch<DashboardStatisticsResponse>(
    `/api/contracts/dashboard/statistics/`,
    { ...filters, ...sort }
  );

export interface ContractsDashboardListResponse {
  id: number;
  farm_name: string;
  farm_country: string;
  farm_town: string;
  land_size: number;
  outgrower_id: number;
  outgrower_first_name: string;
  outgrower_last_name: string;
  lead_outgrower_id: number;
  lead_outgrower_first_name: string | null;
  lead_outgrower_last_name: string | null;
  crop: string;
  expected_harvest_amount: number;
  exposure: number;
}

export interface DashboardFiltersProps {
  search?: string;
  outgrower?: number;
  lead_outgrower?: number;
  country?: string;
  status?: string;
  crop?: number;
}

export const useContractsDashboardList = ({
  filters,
  sort
}: {
  filters?: DashboardFiltersProps;
  sort?: SortRequestProps;
}) =>
  useFetch<Array<ContractsDashboardListResponse>>(
    `/api/contracts/dashboard/list/`,
    { ...filters, ...sort }
  );
