import { MaizeIcon, SugarcaneIcon, TobaccoIcon } from 'assets';
import { get } from 'lodash';

import { CropChoicesListResponse } from 'entities/Crops/sdk';
import { DashboardFiltersProps } from 'entities/Dashboard/sdk';

import ClickableChip from 'components/ClickableChip';

interface ICropChipList {
  onFilter: (filters: DashboardFiltersProps) => void;
  filters: DashboardFiltersProps;
  crops: Array<CropChoicesListResponse>;
}

type IconKey = 'Maize' | 'Sugarcane' | 'Tobacco';

const iconMapper: Record<
  IconKey,
  React.FunctionComponent<React.SVGProps<SVGSVGElement>>
> = {
  Maize: MaizeIcon,
  Sugarcane: SugarcaneIcon,
  Tobacco: TobaccoIcon
};

interface IconRendererProps {
  iconName: string;
  className?: string;
}

const IconRenderer: React.FunctionComponent<IconRendererProps> = ({
  iconName,
  className
}) => {
  const IconComponent = get(iconMapper, iconName, MaizeIcon);
  return <IconComponent className={className} />;
};

const CropChipList = ({ crops, onFilter, filters }: ICropChipList) => {
  const handleChipClick = (chipKey: number | string) => {
    const cropId = chipKey as number;

    onFilter({ crop: cropId, status: filters.status });
  };

  return (
    <div className="shadow-[rgba(16, 24, 40, 0.05)] flex gap-2 rounded-xl border border-solid border-[#d4dae1] bg-white p-3 shadow-sm">
      {crops.map((crop) => (
        <ClickableChip
          key={crop.crop_id}
          chipKey={crop.crop_id}
          icon={<IconRenderer iconName={crop.name} />}
          label={crop.name}
          classes={{
            root: 'border border-solid border-[#062D29] px-4 py-[7px] m-0 text-sm'
          }}
          isClicked={filters.crop === crop.crop_id}
          onClick={handleChipClick}
        />
      ))}
    </div>
  );
};

export default CropChipList;
