import { BASE_URL, SortRequestProps, post, useFetch } from 'utils/sdk';

export interface ContractsDashboardListResponse {
  id: number;
  farm_name: string;
  farm_country: string;
  farm_town: string;
  land_size: number;
  outgrower_id: number;
  outgrower_first_name: string;
  outgrower_last_name: string;
  lead_outgrower_first_name: string | null;
  lead_outgrower_last_name: string | null;
  crop: string;
  expected_harvest_amount: number;
  exposure: number;
}

export interface ContractListResponse {
  id: number;
  outgrower_id: number;
  outgrower_first_name: string;
  outgrower_last_name: string;
  crop: string;
  execution_start_date: string;
  expected_harverst_date: string;
  expected_harvest_amount: number;
  actual_harvest_amount: number;
  actual_harvest_date: string;
  expected_farmgate_price: number;
  exposure: number;
  termination_reason: string | null;
}

export interface FertilizerSummaryProps {
  name: string;
  amount: number;
  unit: string;
  value: number;
}

export interface ChemicalSummaryProps {
  name: string;
  amount: number;
  unit: string;
  value: number;
}

export interface ContractSummaryPreviewResponse {
  plant_material_amount: number;
  plant_material_unit: string;
  plant_material_value: number;
  fertilizers: Array<FertilizerSummaryProps>;
  chemicals: Array<ChemicalSummaryProps>;
  yield_per_ha: number;
  total_yield: number;
  price_per_ton: number;
  total_amount: number;
  revenue: number;
}

export interface ContractSummaryPreviewProps {
  outgrower_id: number;
  crop_id: number;
  crop_variety_id: number;
  farm_id: number;
  land_size: number;
  signature_date: string;
  execution_start_date: string;
  expected_harverst_date: string;
}

export interface ContractsListFiltersProps {
  status: string;
  search?: string;
}

export interface ContractTerminateProps {
  reason: string;
}

export interface ContractEndProps {
  contractId: string;
}

export interface ContractHarvestRecordProps {
  harvest_date: string;
  harvest_amount: number;
}

export const useContractsList = ({
  filters,
  sort
}: {
  filters: ContractsListFiltersProps;
  sort: SortRequestProps;
}) =>
  useFetch<Array<ContractListResponse>>(`/api/contracts/`, {
    ...filters,
    ...sort
  });

export const contractSummaryPreview = (data: ContractSummaryPreviewProps) =>
  post<ContractSummaryPreviewResponse>(
    `${BASE_URL}/api/contracts/summary/preview/`,
    data
  );

export interface ContractCreateProps {
  outgrower_id: number;
  crop_id: number;
  crop_variety_id: number;
  farm_id: number;
  land_size: number;
  signature_date: string;
  execution_start_date: string;
  expected_harverst_date: string;
}

export const contractCreate = (data: ContractCreateProps) =>
  post(`${BASE_URL}/api/contracts/create/`, data);

export const contractTerminate = ({
  contractId,
  data
}: {
  contractId: string;
  data: ContractTerminateProps;
}) => post(`${BASE_URL}/api/contracts/${contractId}/terminate/`, data);

export const contractEnd = (contractId: string) =>
  post(`${BASE_URL}/api/contracts/${contractId}/end/`);

export const contractHarvestRecordCreate = ({
  contractId,
  data
}: {
  contractId: string;
  data: ContractHarvestRecordProps;
}) =>
  post(`${BASE_URL}/api/contracts/${contractId}/harvest-record/create/`, data);

export interface ContractDiaryProps {
  id: number;
  land_size: number;
  execution_start_date: string;
  expected_harverst_date: string;
  expected_farmgate_price: number;
  crop: string;
  crop_variety: string;
}
export const useContractDiary = ({ contractId }: { contractId?: string }) =>
  useFetch<ContractDiaryProps>(`/api/contracts/${contractId}/diary/`);
