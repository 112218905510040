import { DocumentIcon } from 'assets';

import { ContractDiaryProps } from 'entities/Contracts/sdk';

import Text from 'components/Text';

interface ContractDetailsProps {
  contract: ContractDiaryProps;
}

interface LabelProps {
  children: React.ReactNode;
}

const Label = ({ children }: LabelProps) => (
  <div className="text-xs font-normal leading-[17px] text-wet-green">
    {children}
  </div>
);

interface ValueProps {
  children: React.ReactNode;
}

const Value = ({ children }: ValueProps) => (
  <Text color="wet-green" className="text-sm font-semibold tracking-[0.15px]">
    {children}
  </Text>
);

const ContractDetails = ({ contract }: ContractDetailsProps) => {
  return (
    <div className="flex w-full flex-col gap-2 rounded-lg bg-white p-2">
      <div className="flex items-center gap-1">
        <DocumentIcon />
        <Text
          color="wet-green"
          fontFamily={['DM Sans']}
          className="text-base leading-6"
        >
          Contract Details
        </Text>
      </div>

      <div className="flex flex-col gap-1">
        <div className="flex items-center justify-between">
          <Label>Number</Label>
          <Value>{contract.id}</Value>
        </div>
        <div className="flex items-center justify-between">
          <Label>Crop</Label>
          <Value>{contract.crop}</Value>
        </div>
        <div className="flex items-center justify-between">
          <Label>Variety</Label>
          <Value>{contract.crop_variety}</Value>
        </div>
        <div className="flex items-center justify-between">
          <Label>Start date</Label>
          <Value>{contract.execution_start_date} </Value>
        </div>
        <div className="flex items-center justify-between">
          <Label>End date</Label>
          <Value>{contract.expected_harverst_date}</Value>
        </div>
        <div className="flex items-center justify-between">
          <Label>Contract Land size</Label>
          <Value>{contract.land_size} ha</Value>
        </div>
        <div className="flex items-center justify-between">
          <Label>Expected farm gate value</Label>
          <Value>{contract.expected_farmgate_price} USD</Value>
        </div>
      </div>
    </div>
  );
};

export default ContractDetails;
