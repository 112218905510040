import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import ContractDetails from 'entities/Contracts/components/ContractDetails';
import { useContractDiary } from 'entities/Contracts/sdk';
import LeadOutgrowerDetails from 'entities/Outgrower/components/LeadOutgrowerDetails';
import OutgrowerDetails from 'entities/Outgrower/components/OutgrowerDetails';
import OutgrowerFarmDetails from 'entities/Outgrower/components/OutgrowerFarmDetails';
import { useOutgrowerDetails, useOutgrowerFarms } from 'entities/Outgrower/sdk';

import ContentLoading from 'components/ContentLoading';
import { useLayout } from 'components/Layout/hooks';
import PageTitle from 'components/PageTitle';

const OutgrowerContractDiary = () => {
  const { setPageTitle, setRightSidebarItems } = useLayout();
  const { outgrowerId, contractId } = useParams();
  const { data: outgrower, isLoading: outgrowerLoading } = useOutgrowerDetails({
    outgrowerId
  });

  const { data: outgrowerFarms, isLoading: farmsLoading } = useOutgrowerFarms({
    outgrowerId
  });

  const { data: contractDiary, isLoading: contractDiaryLoading } =
    useContractDiary({
      contractId: contractId
    });

  useEffect(() => {
    setPageTitle(<PageTitle text="Contract Diary Manager" />);

    return () => setPageTitle(null);
  }, [setPageTitle]);

  useEffect(() => {
    if (!outgrower || !outgrowerFarms || !contractDiary) return;

    console.log('outgrower.lead_outgrower', outgrower.lead_outgrower);
    setRightSidebarItems([
      <OutgrowerDetails outgrower={outgrower} />,
      outgrower.lead_outgrower && (
        <LeadOutgrowerDetails leadOutgrower={outgrower.lead_outgrower} />
      ),
      <OutgrowerFarmDetails farm={outgrowerFarms[0]} />,
      <ContractDetails contract={contractDiary} />
    ]);

    return () => setRightSidebarItems([]);
  }, [setRightSidebarItems, outgrower, outgrowerFarms, contractDiary]);

  return (
    <div>
      {(outgrowerLoading || farmsLoading || contractDiaryLoading) && (
        <ContentLoading />
      )}
    </div>
  );
};

export default OutgrowerContractDiary;
