import { PeopleBlackIcon } from 'assets';

import { OutgrowerDetailsResponse } from 'entities/Outgrower/sdk';

import Text from 'components/Text';

interface LeadOutgrowerDetailsProps {
  leadOutgrower: OutgrowerDetailsResponse['lead_outgrower'];
}

interface LabelProps {
  children: React.ReactNode;
}

const Label = ({ children }: LabelProps) => (
  <div className="text-xs font-normal leading-[17px] text-wet-green">
    {children}
  </div>
);

interface ValueProps {
  children: React.ReactNode;
}

const Value = ({ children }: ValueProps) => (
  <Text color="wet-green" className="text-sm font-semibold tracking-[0.15px]">
    {children}
  </Text>
);

const LeadOutgrowerDetails = ({ leadOutgrower }: LeadOutgrowerDetailsProps) => {
  return (
    <div className="flex w-full flex-col gap-2 rounded-lg bg-white p-2">
      <div className="flex items-center gap-1">
        <PeopleBlackIcon />
        <Text
          color="wet-green"
          fontFamily={['DM Sans']}
          className="text-base leading-6"
        >
          Lead Farmer Details
        </Text>
      </div>

      <div className="flex flex-col gap-1">
        <div className="flex items-center justify-between">
          <Label>Email</Label>
          <Value>
            {leadOutgrower.first_name} {leadOutgrower.last_name}
          </Value>
        </div>
        <div className="flex items-center justify-between">
          <Label>Phone</Label>
          <Value>{leadOutgrower.phone_number}</Value>
        </div>
      </div>
    </div>
  );
};

export default LeadOutgrowerDetails;
