const BASE_BACKEND_URL = import.meta.env.VITE_BASE_BACKEND_URL;
export const BASE_URL = `${BASE_BACKEND_URL}`;

export enum URLS {
  LOGIN = '/login',
  DASHBOARD = '/dashboard',
  OUTGROWERS = '/outgrowers',
  OUTGROWER_CREATE = '/outgrowers/create',
  OUTGROWER_PROFILE = '/outgrowers/:outgrowerId/profile',
  OUTGROWER_CONTRACTS = '/outgrowers/:outgrowerId/contracts',
  OUTGROWER_CONTRACT_CREATE = '/outgrowers/:outgrowerId/contracts/create',
  OUTGROWER_CONTRACT_DIARY = '/outgrowers/:outgrowerId/contracts/:contractId/diary',
  OUTGROWER_CONTRACT_GALLERY = '/outgrowers/:outgrowerId/contracts/:contractId/gallery',
  CONTRACTS = '/contracts'
}
