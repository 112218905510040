import { useState } from 'react';
import { useForm } from 'react-hook-form';

import dayjs from 'dayjs';

import { contractEnd, contractTerminate } from 'entities/Contracts/sdk';

import Button from 'components/Button';
import Dialog from 'components/Dialog';
import { FormRadioField, FormTextField } from 'components/Form';
import Text from 'components/Text';

interface ContractCloseDialogProps {
  contractId: number;
  onClose: () => void;
}

const CLOSE_OPTIONS = [
  { label: 'Season end', value: 'season_end' },
  { label: 'Terminate early', value: 'terminate' }
];

export interface FormProps {
  close_reason: string;
  close_text_reason: string;
  close_harvest_date: dayjs.Dayjs;
  close_harvest_amount: number;
}

const ContractCloseDialog = ({
  contractId,
  onClose
}: ContractCloseDialogProps) => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<FormProps>();

  const [closeReason, setCloseReason] = useState<string>();

  const onSubmit = (data: any) => {
    if (closeReason === 'terminate') {
      const apiData = {
        reason: data['close_text_reason']
      };
      return contractTerminate({
        contractId: contractId.toString(),
        data: apiData
      }).then(() => {
        onClose();
      });
    }

    if (closeReason === 'season_end') {
      return contractEnd(contractId.toString()).then(() => {
        onClose();
      });
    }
  };

  const onSelectOption = (option: any) => {
    setValue('close_reason', option);
    setCloseReason(option);
  };
  return (
    <Dialog onClose={onClose} title="Close Contract">
      <div className="flex flex-col pt-6">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex h-full flex-col gap-5"
        >
          <div className="flex flex-col gap-4">
            <Text
              classes={{
                root: 'tracking-[0.15px] text-wet-green leading-[30px] font-medium text-xl'
              }}
            >
              Reason to close:
            </Text>
            <FormRadioField
              name="close_reason"
              control={control}
              options={CLOSE_OPTIONS}
              className="py-3"
              radioGroupProps={{
                className: 'gap-4 flex flex-row',
                onChange: onSelectOption
              }}
            />
            {closeReason === 'terminate' && (
              <FormTextField
                name="close_text_reason"
                control={control}
                rules={{ required: 'This field is required' }}
                fieldProps={{
                  label: 'Reason',
                  placeholder: 'Specify the reason for the early termination',
                  error: !!errors.close_text_reason,
                  helperText: errors.close_text_reason?.message,
                  multiline: true,
                  rows: 4,
                  sx: {
                    '& .MuiOutlinedInput-root': {
                      padding: '10px 12px',
                      height: '128px',
                      width: '460px'
                    }
                  }
                }}
              />
            )}
          </div>
          {closeReason && (
            <div className="flex justify-center gap-3">
              <Button
                variant="outlined"
                className="font-medium"
                color="wetGreen"
                onClick={onClose}
              >
                Discard
              </Button>
              <Button
                type="submit"
                variant="contained"
                className="font-medium"
                color="wetGreen"
              >
                Close contract
              </Button>
            </div>
          )}
        </form>
      </div>
    </Dialog>
  );
};

export default ContractCloseDialog;
