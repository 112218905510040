import Avatar from '@mui/material/Avatar';
import PersonIcon from 'assets/person.svg';

import { OutgrowerDetailsResponse } from 'entities/Outgrower/sdk';

import Text from 'components/Text';

interface OutgrowerDetailsProps {
  outgrower: OutgrowerDetailsResponse;
}

interface LabelProps {
  children: React.ReactNode;
}

const Label = ({ children }: LabelProps) => (
  <div className="text-xs font-normal leading-[17px] text-wet-green">
    {children}
  </div>
);

interface ValueProps {
  children: React.ReactNode;
}

const Value = ({ children }: ValueProps) => (
  <Text color="wet-green" className="text-sm font-semibold tracking-[0.15px]">
    {children}
  </Text>
);

const OutgrowerDetails = ({ outgrower }: OutgrowerDetailsProps) => {
  return (
    <div className="flex w-full flex-col gap-2 rounded-lg bg-white p-2">
      <div className="flex items-center gap-1">
        <PersonIcon />
        <Text
          color="wet-green"
          fontFamily={['DM Sans']}
          className="text-base leading-6"
        >
          Outgrower Details
        </Text>
      </div>
      <div className="flex items-center gap-3">
        <Avatar className="h-[54px] w-[54px]" />
        <Text
          color="wet-green"
          className="text-lg font-semibold tracking-[0.15px]"
        >
          {outgrower.first_name} {outgrower.last_name}
        </Text>
      </div>
      <div className="flex flex-col gap-1">
        <div className="flex items-center justify-between">
          <Label>Email</Label>
          <Value>{outgrower.email}</Value>
        </div>
        <div className="flex items-center justify-between">
          <Label>Phone</Label>
          <Value>{outgrower.phone_number}</Value>
        </div>
        <div className="flex items-center justify-between">
          <Label>Town</Label>
          <Value>{outgrower.town}</Value>
        </div>
        <div className="flex items-center justify-between">
          <Label>Country</Label>
          <Value>{outgrower.country}</Value>
        </div>
      </div>
    </div>
  );
};

export default OutgrowerDetails;
