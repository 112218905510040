import { ReactNode, useState } from 'react';
import { Outlet } from 'react-router-dom';

import RightSidebar from 'components/RightSidebar';
import SideNavigation from 'components/SideNavigation';
import TopNavigation from 'components/TopNavigation';

const DetailsLayout = () => {
  const [pageTitle, setPageTitle] = useState<ReactNode>(null);
  const [actionButton, setActionButton] = useState<ReactNode>(null);
  const [rightSideBarItems, setRightSidebarItems] = useState<Array<ReactNode>>(
    []
  );

  return (
    <div className="flex h-screen w-screen">
      <SideNavigation />
      <div className="w-full flex-1">
        <TopNavigation pageTitle={pageTitle} actionButton={actionButton} />
        <div className="h-[calc(100vh-66px)]">
          <Outlet
            context={{ setPageTitle, setActionButton, setRightSidebarItems }}
          />
        </div>
      </div>
      <RightSidebar rightSideBarItems={rightSideBarItems} />
    </div>
  );
};

export default DetailsLayout;
