import { useState } from 'react';

import Box from '@mui/material/Box';
import { ChevronDownIcon, ChevronUpIcon, CloseIcon } from 'assets';
import { get } from 'lodash';

import { DashboardFiltersProps } from 'entities/Dashboard/sdk';

import AutoComplete from 'components/AutoComplete';
import Button from 'components/Button';
import Card from 'components/Card';
import { FormControlLabel } from 'components/Form';
import { Radio } from 'components/Radio';
import Text from 'components/Text';
import TextField from 'components/TextField';

interface Option {
  label: string;
  value: number | string;
}

interface DashboardFilterProps {
  label: string;
  filterName: string;
  filters: DashboardFiltersProps;
  onFilter: (filters: DashboardFiltersProps) => void;
  filterOptions: Array<Option>;
}

const DashboardFilter = ({
  filters,
  filterName,
  label,
  onFilter,
  filterOptions
}: DashboardFilterProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const optionFromFilters = filterOptions.find(
    (option) => option.value === get(filters, filterName)
  );

  const initialSelectedOption = filters ? optionFromFilters : undefined;

  const [selectedOption, setSelectedOption] = useState(initialSelectedOption);

  const onSearch = () => {
    onFilter({ [filterName]: selectedOption?.value, crop: filters.crop });
    setIsOpen(false);
  };

  const onReset = () => {
    setSelectedOption(undefined);
    onFilter({ [filterName]: undefined, crop: filters.crop });
    setIsOpen(false);
  };

  const onAutoCompleteChange = (_: any, value: Option | null) => {
    setSelectedOption(value || undefined);
  };

  return (
    <div className="relative flex flex-col gap-2">
      <div
        className="flex h-8 items-center justify-start gap-1 rounded-2xl border border-solid border-[#d0d5dd] bg-white px-3.5 py-2.5 shadow-[0_1px_2px_0px_#1018280d]"
        onClick={() => setIsOpen(!isOpen)}
      >
        <Text className="font-['Inter'] text-[13px] font-semibold leading-[19.5px] text-[#757575]">
          {label}:
        </Text>
        <Text
          color="wet-green"
          className="font-['Inter'] text-[13px] font-semibold leading-[19.5px]"
        >
          {optionFromFilters?.label || 'All'}
        </Text>
        {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </div>

      {isOpen && (
        <Card className="absolute left-0 top-10 z-10 flex h-[370px] w-[247px] flex-col justify-between gap-3 rounded-lg px-3 py-4 shadow-[4px_8px_17.8px_10px_#0000000f,4px_4px_4px_0px_#0000000f,0px_2px_0px_-1px_#EEE]">
          <div className="flex flex-col gap-3">
            <div className="flex items-center justify-between">
              <Text
                color="wet-green"
                className="font-bold leading-6 tracking-[0.4px]"
              >
                Filter
              </Text>
              <CloseIcon
                onClick={() => setIsOpen(false)}
                className="cursor-pointer"
              />
            </div>

            <AutoComplete
              open
              value={selectedOption}
              options={filterOptions}
              onChange={onAutoCompleteChange}
              renderInput={(params) => (
                <TextField placeholder="Search your farmers" {...params} />
              )}
              renderOption={({ key, ...props }, option) => {
                return (
                  <Box component="li" key={key} {...props}>
                    <FormControlLabel
                      value={option.value}
                      label={option.label}
                      control={<Radio />}
                      checked={selectedOption?.value === option.value}
                    />
                  </Box>
                );
              }}
              slotProps={{
                paper: {
                  classes: {
                    root: 'max-h-[200px] overflow-y-auto shadow-none mt-[10px]'
                  }
                }
              }}
            />
          </div>

          <div className="flex justify-center gap-3">
            <Button
              variant="outlined"
              className="font-medium"
              color="wetGreen"
              onClick={onReset}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              className="font-medium"
              color="wetGreen"
              onClick={onSearch}
            >
              Apply
            </Button>
          </div>
        </Card>
      )}
    </div>
  );
};

export default DashboardFilter;
