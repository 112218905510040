import { generatePath, useNavigate } from 'react-router-dom';

import { OutgrowerCreateResponse } from 'entities/Outgrower/sdk';

import Button from 'components/Button';
import Dialog from 'components/Dialog';
import Text from 'components/Text';

import { URLS } from 'config/urls';

interface ProfileCreateDialogProps {
  onClose: () => void;
  outgrower: OutgrowerCreateResponse;
}

const ProfileCreateDialog = ({
  onClose,
  outgrower
}: ProfileCreateDialogProps) => {
  const navigate = useNavigate();

  return (
    <Dialog onClose={onClose} title="Profile created">
      <div className="py-8">
        <Text
          classes={{
            root: 'tracking-[0.15px] text-wet-green leading-[30px] font-medium text-xl pb-5'
          }}
        >
          You've successfully created a new outgrower profile.
        </Text>
        <Text
          classes={{
            root: 'tracking-[0.15px] text-wet-green leading-[30px] font-normal text-base text-center'
          }}
        >
          Add a contract to the farm to start working with{' '}
          {outgrower.first_name} {outgrower.last_name}.
        </Text>
      </div>

      <div className="flex justify-center gap-3">
        <Button
          variant="outlined"
          className="font-medium"
          color="wetGreen"
          onClick={() => navigate(URLS.OUTGROWERS)}
        >
          View all outgrowers
        </Button>
        <Button
          variant="contained"
          className="font-medium"
          color="wetGreen"
          onClick={() =>
            navigate(
              generatePath(URLS.OUTGROWER_CONTRACT_CREATE, {
                outgrowerId: outgrower.id.toString()
              })
            )
          }
        >
          Add Contract
        </Button>
      </div>
    </Dialog>
  );
};

export default ProfileCreateDialog;
