import TextField, { TextFieldProps } from 'components/TextField';

import {
  Control,
  Controller,
  FieldValues,
  Path,
  RegisterOptions
} from 'utils/forms';

interface FormTextFieldProps<T extends FieldValues> {
  name: Path<T>;
  control: Control<T, any>;
  rules?: RegisterOptions<T>;
  fieldProps: TextFieldProps;
}

const FormTextField = <T extends FieldValues>({
  name,
  control,
  rules,
  fieldProps
}: FormTextFieldProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <TextField
          {...fieldProps}
          {...field}
          value={field.value || ''}
        ></TextField>
      )}
    />
  );
};

export default FormTextField;
