import { useEffect, useState } from 'react';

import DashboardListTable from 'entities/Contracts/components/DashboardListTable';
import { CONTRACT_STATUSES } from 'entities/Contracts/constants';
import { useCropChoicesList } from 'entities/Crops/sdk';
import CropChipList from 'entities/Dashboard/components/CropChipList';
import DashboardFilters from 'entities/Dashboard/components/DashboardFilters';
import DashboardStatistics from 'entities/Dashboard/components/DashboardStatistics';
import { useContractsDashboardList } from 'entities/Dashboard/sdk';
import { useDashboardStatistics } from 'entities/Dashboard/sdk';
import { DashboardFiltersProps } from 'entities/Dashboard/sdk';

import Card from 'components/Card';
import ContentLoading from 'components/ContentLoading';
import { useLayout } from 'components/Layout/hooks';
import PageTitle from 'components/PageTitle';

import { ListSortProps, buildSort } from 'utils/sdk';

const Dashboard = () => {
  const { setPageTitle } = useLayout();
  const [sort, setSort] = useState<ListSortProps>({
    sort: 'id',
    direction: 'asc'
  });

  useEffect(() => {
    setPageTitle(<PageTitle text="Dashboard"></PageTitle>);

    return () => setPageTitle(null);
  }, [setPageTitle]);

  const { data: crops, isLoading: cropsLoading } = useCropChoicesList();

  useEffect(() => {
    if (crops) {
      const defaultCrop = crops?.find((crop) => crop.name === 'Maize');
      setFilters((prevFilters) => ({
        ...prevFilters,
        crop: defaultCrop?.crop_id
      }));
    }
  }, [crops]);

  const [filters, setFilters] = useState<DashboardFiltersProps>({
    status: CONTRACT_STATUSES.ACTIVE
  });

  const { data: contracts, isLoading: contractsLoading } =
    useContractsDashboardList({
      filters,
      sort: buildSort(sort)
    });

  const { data: contractsForOutgrowerFilter } = useContractsDashboardList({});

  const { data: statistics, isLoading: statisticsLoading } =
    useDashboardStatistics({
      filters,
      sort: buildSort(sort)
    });

  return (
    <div className="flex h-full flex-col gap-3 bg-[#F8F8F8] px-[26px] py-5">
      {!cropsLoading && crops && (
        <CropChipList crops={crops} onFilter={setFilters} filters={filters} />
      )}
      {!statisticsLoading && statistics && (
        <DashboardStatistics statistics={statistics} />
      )}
      <Card
        classes={{
          root: 'flex flex-col h-full w-full gap-5 p-5 rounded-xl border border-solid border-[#d4dae1] shadow-sm shadow-[rgba(16, 24, 40, 0.05)] '
        }}
      >
        <DashboardFilters
          filters={filters}
          contracts={contractsForOutgrowerFilter}
          onFilter={setFilters}
        />

        {contractsLoading && <ContentLoading />}
        {!contractsLoading && contracts && (
          <DashboardListTable
            contracts={contracts}
            onSort={setSort}
            sort={sort}
          />
        )}
      </Card>
    </div>
  );
};

export default Dashboard;
