import { redirect } from 'react-router-dom';

import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs';
import { KeyedMutator, default as useSWR } from 'swr';

import { getToken, removeToken } from 'utils/storage';

import { BASE_URL, URLS } from 'config/urls';

export type SwrMutator = KeyedMutator<any>;

export { BASE_URL };

export type IConfig<D> = AxiosRequestConfig<D> & {
  isFileUpload?: boolean;
  useSharableToken?: boolean;
};

export const getConfig = <D>(params?: IConfig<D>) => {
  const token = getToken();
  const { headers = {}, isFileUpload = false, ...options } = params || {};
  const contentType = isFileUpload ? 'multipart/form-data' : 'application/json';
  const config: IConfig<D> = {
    headers: {
      'Content-Type': contentType,
      Authorization: `Bearer ${token}`,
      ...headers
    } as IConfig<D>['headers'],
    withCredentials: true,
    ...options
  };
  return config;
};

const extractData = <R, D>(response: AxiosResponse<R, D>) => response.data;
const apiErrorMessages = [
  'Signature has expired.',
  'Token has expired.',
  'Error decoding token.'
];

const handleErrorResponse = (error: any) => {
  if (
    error.response?.status === 401 &&
    apiErrorMessages.includes(error?.response?.data?.message)
  ) {
    // Perhaps logout the person by sending them to "/"
    removeToken();
    redirect(URLS.LOGIN);
  }

  if (error.response?.status === 400 && error.response?.data.extra) {
    // Handle form errors
    const formErrors = Object.keys(error.response.data.extra.fields).map(
      (fieldName) => {
        if (fieldName === 'non_field_errors') {
          return {
            fieldName: 'root.serverError',
            messages: error.response.data.extra.fields[fieldName]
          };
        }

        return {
          fieldName,
          messages: error.response.data.extra.fields[fieldName]
        };
      }
    );

    const setFormErrors = (
      setError: (
        name: string,
        error: { type: 'custom'; message: string }
      ) => void
    ) => {
      formErrors?.map(
        ({
          fieldName,
          messages
        }: {
          fieldName: string;
          messages: string[];
        }) => {
          setError(fieldName as any, {
            type: 'custom',
            message: messages.join(' ')
          });
        }
      );
    };
    return Promise.reject({
      ...error.response.data,
      setFormErrors,
      formErrors
    });
  }

  return Promise.reject(error?.response?.data || 'Something went wrong');
};

export const get = <R, D = any>(url: string, params?: IConfig<D>): Promise<R> =>
  axios
    .get<R>(url, getConfig(params))
    .then(extractData)
    .catch(handleErrorResponse);

export const post = <R, D = any>(
  url: string,
  data?: D,
  params?: IConfig<D>
): Promise<R> =>
  axios
    .post<R>(url, data, getConfig(params))
    .then(extractData)
    .catch(handleErrorResponse);

export const useFetch = <D>(
  url: string | undefined,
  queryParams?: Record<string, any>
) => {
  const queryString = queryParams
    ? `?${qs.stringify(queryParams, { arrayFormat: 'repeat' })}`
    : '';
  const fullUrl = url ? `${BASE_URL}${url}${queryString}` : undefined;
  return useSWR<D>(fullUrl, get, {
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true
  });
};

export interface SortRequestProps {
  sort?: string;
}

export interface ListSortProps {
  sort?: string;
  direction: string;
}

export const buildSort = (sort: ListSortProps) => ({
  sort: `${sort.direction === 'desc' ? '-' : ''}${sort.sort}`
});
