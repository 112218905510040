import { generatePath, useNavigate } from 'react-router-dom';

import { ListAddIcon } from 'assets';

import { OutgrowerListResponse } from 'entities/Outgrower/sdk';

import { TableBodyCell, TableRow } from 'components/Table';
import Text from 'components/Text';

import { URLS } from 'config/urls';

interface ExpandedRowProps {
  outgrower: OutgrowerListResponse;
}

const ExpandedRow = ({ outgrower }: ExpandedRowProps) => {
  const navigate = useNavigate();
  const farm = outgrower.farms[0];

  return (
    <TableRow
      key={outgrower.id}
      classes={{
        root: 'bg-[#b3cbbd]/10'
      }}
    >
      <TableBodyCell content="" />
      <TableBodyCell content="" />
      <TableBodyCell
        content={farm.name}
        className="text-xs font-medium leading-[14px]"
      />
      <TableBodyCell
        content={
          <div className="flex flex-col gap-1 text-xs font-medium leading-[14px]">
            {farm.contracts.map((contract) => (
              <div
                key={contract.id}
                className="align-center flex cursor-pointer justify-center gap-1"
                onClick={() =>
                  navigate(
                    generatePath(URLS.OUTGROWER_CONTRACTS, {
                      outgrowerId: outgrower.id.toString()
                    })
                  )
                }
              >
                <div className="underline decoration-wet-green">
                  {contract.id}
                </div>
                <div>({contract.crop})</div>
              </div>
            ))}
          </div>
        }
      />
      <TableBodyCell
        content={
          <div
            className="flex cursor-pointer items-center justify-center gap-1"
            onClick={() =>
              navigate(
                generatePath(URLS.OUTGROWER_CONTRACT_CREATE, {
                  outgrowerId: outgrower.id.toString()
                })
              )
            }
          >
            <ListAddIcon />
            <Text
              color="wet-green"
              className="text-xs font-medium leading-[14px]"
            >
              Add Contract
            </Text>
          </div>
        }
      />
    </TableRow>
  );
};

export default ExpandedRow;
