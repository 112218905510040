import { useEffect } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { PlusIcon } from 'assets';

import OutgrowerFarmAndContracts from 'entities/Outgrower/components/OutgrowerFarmAndContracts';
import OutgrowerProfileCard from 'entities/Outgrower/components/OutgrowerProfileCard';
import OutgrowerProfileStatistics from 'entities/Outgrower/components/OutgrowerProfileStatistics';
import { useOutgrowerDetail } from 'entities/Outgrower/sdk';

import Button from 'components/Button';
import ContentLoading from 'components/ContentLoading';
import { useLayout } from 'components/Layout/hooks';
import PageTitle from 'components/PageTitle';

import { URLS } from 'config/urls';

const OutgrowerProfile = () => {
  const { setPageTitle, setActionButton } = useLayout();
  const { outgrowerId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setPageTitle(<PageTitle text="Profile"></PageTitle>);

    return () => setPageTitle(null);
  }, [setPageTitle]);

  const { data: outgrower, isLoading: outgrowerLoading } = useOutgrowerDetail({
    outgrowerId
  });

  useEffect(() => {
    setActionButton(
      <Button
        startIcon={<PlusIcon />}
        variant="contained"
        color="wetGreen"
        onClick={() =>
          navigate(
            generatePath(URLS.OUTGROWER_CONTRACT_CREATE, {
              outgrowerId: outgrowerId as string
            })
          )
        }
      >
        Add Contract
      </Button>
    );

    return () => setActionButton(null);
  }, [setActionButton, navigate, outgrowerId]);

  return (
    <div className="flex h-full flex-col gap-5 p-5">
      {outgrowerLoading && <ContentLoading />}

      {outgrower && !outgrowerLoading && (
        <>
          <OutgrowerProfileStatistics outgrower={outgrower} />
          <div className="flex gap-6">
            <OutgrowerProfileCard outgrower={outgrower} />
            <OutgrowerFarmAndContracts outgrower={outgrower} />
          </div>
        </>
      )}
    </div>
  );
};

export default OutgrowerProfile;
